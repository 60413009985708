// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}
#searchform{
  // padding-bottom: 24px;
  display: flex;
 justify-content: center;
}

.comment-form input[type=email],
.comment-form input[type=text],
.comment-form input[type=url],
.comment-form textarea,
.form-control,
.search-form .search-field{
  // width: 80%;
  padding: .15rem .75rem;
  // display: inline-block;
  border-radius: 0;
  // color: black;
  font-family: $times;
  font-size: 14px;
  color: $link-color;
  // float: left;
}
@media (max-width: 992px){
  // #searchform{
    // justify-content: center;
  // }
  #s.form-control{
    width: 300px;
  }
}

.comment-form input[type=email]:focus,
.comment-form input[type=text]:focus,
.comment-form input[type=url]:focus,
.comment-form textarea:focus,
.form-control:focus,
.search-form .search-field:focus{
  border: 1px solid $link-color;
  color: $link-color;
}


.search_form_but{
  border: 1px solid $link-color;
  background-color: $link-color;
  padding: .15rem .75rem;
  line-height: 1.25;
  font-size: 14px;
  color: white;
  // float: left;
}
.search_form_but:hover{
  border: 1px solid $grey;
  background-color: $grey;
}


input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: $link-color !important;
  color: red !important;
}


input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: blue;
}

input:-webkit-autofill {
    border: 1px solid #ccc;
    -webkit-box-shadow: inset 0 0 0px 9999px white;
}

input:focus,
input:-webkit-autofill:focus {
    border-color: #66afe9;
    -webkit-box-shadow: inset 0 0 0px 9999px white,
                        0 0 8px rgba(102, 175, 233, 0.6);
}
