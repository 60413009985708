.nav{
  flex-direction: column;
}


.navbar-toggle .icon-bar {
  display: block;
  width: 26px;
  height: 6px;
  border-radius: 1px;
  position: relative;
  transition: all .3s ease-in-out;
  background: $brand-primary;
  background: $white;
}
.navbar-toggle .icon-bar+.icon-bar {
    margin-top: 4px;
}
.navbar-toggle .icon-bar {
  position: relative;
  transition: all 300ms ease-in-out;
}

.navbar-toggle.active .icon-bar:nth-of-type(1) {
  top: 10px;
  // height: 4px;
  transform: rotate(45deg);
}

.navbar-toggle.active .icon-bar:nth-of-type(2) {
  // background-color: transparent;
  top: 0px;
  // height: 4px;
  transform: rotate(45deg);
}

.navbar-toggle.active .icon-bar:nth-of-type(3) {
  top: -10px;
  // height: 4px;
  transform: rotate(-45deg);
}

ul.children a,
.sub-menu a{
  font-size: 18px;
  /* font-style: italic; */
  font-family: GaramondClassicFSItalic,Times New Roman,Times,serif;
  color: #959493;
  // line-height: 18px;
  &:after{
    content: '«';
    margin-left: 2px;
  }
  &:before{
    content: '»';
    margin-right: 2px;
  }
}
ul.children a:hover,
.sub-menu a:hover{
  color: $black;
}
ul.children,
.sub-menu{
  padding-bottom: 10px!important;
}

.accounts,
.years{
  ul{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  li {
    display: inline-block;
  }
  li:after{
    content: "/";
    padding: 0 5px;
  }

  li:last-child:after{
    content: "";
    padding: 0;

  }
}
.tags{
  .tag-item:after{
    content: "/";
    padding: 0 5px;
  }
  .tag-item:last-child:after{
    content: "";
    padding: 0;
  }
}
.taglist{
  div{
    display: inline-block;
  }
  .tag-letter{
    padding: 0 5px;
  }
}

