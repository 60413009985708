.page-header{
  border-bottom: none;
}



@media (min-width: 768px){
  .panel-grid-cell{
    border-left: 1px solid #A9A9A9;
  }
}
.panel-grid-cell .panel-grid-cell{
  border: none;
}

.navbar-toggle {
  position: relative;
  float: none;
  // margin-right: 15px;
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  // background-color: $grey;
  background-color: rgb(200,200,200);
  background-image: none;
  border: none;
  border-radius: 0;
  outline: none;
  &:focus{
    outline: none;
  }
}



.figure-img, .wp-caption {
  margin-bottom: 0;
  line-height: 1;
}

//
// Jasny-Bootstrap
//
#pg-2-1 .panel-grid-cell,
#pg-2-4 .panel-grid-cell,
#pg-2-6 .panel-grid-cell{
  margin-left: -15px;
}
