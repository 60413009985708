.banner {
  padding-bottom: 35px;
  .container {
    // border-bottom: solid 2px #000000;
  }

  .brand {
    font-size: 3em;
    // line-height: 110px;
  }

  .berlin-singapore {
    color: $grey;
    font-size: 20px;
  }

  .borders {
    &.top {
      border-top: 1px dotted black;
    }
    &.bottom {
      border-bottom: 2px solid black;
    }
  }
}
.icon-facebook {
  padding: 0 5px;
}
.icon-instagram {
  padding-right: 5px;
}
.icon-facebook svg,
.icon-instagram svg {
  margin-bottom: -3px;
}
.icon-facebook:hover svg path:first-child {
  fill: black;
}
.icon-instagram:hover svg #Ebene_2 path {
  fill: black;
}

.flex-header {
  height: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
}
