.sidebar{
  ul{
    list-style: none;
    padding: 0;
  }
  h3{
    font-size: 12px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 3px;
  }
  // .years h3{
    // margin-top: 5px;
  // }

  hr{
    width: 30px;
    border-top: 2px solid rgba(0,0,0,1);
    margin-top: 1rem;
    margin-bottom: .9rem;
  }
  // .nav{
  //   margin-bottom: 23px;
  // }
  .sidebar-search{
    margin-bottom: 8px;
  }
}



.sidebar-container{
  background: $white;
  height: 100%;
  padding: 35px 15px 0 15px;
}

.nav>li{
  line-height: 26px;
  &>a {
    font-family: GaramondClassicFSItalic,"Times New Roman", Times, serif;
    font-size: 22px;
    text-align: center;
    padding: 0;


    padding: 0px;
    margin: 0px;
    text-align: center;
    text-decoration: none;
    color: #004a75;
  }
}
ul.children li,
.sub-menu li{
  line-height: 22px;
}
.nav .active >a,
.nav li.current-cat-parent >a,
.nav li.current-cat >a,
ul.children .current-cat a,
.nav .active .sub-menu .active a{
  font-family: GaramondClassicFSBold,"Times New Roman", Times, serif;
  color: $black;
}

.current-cat a,
.nav>li.current-cat a,
.nav .active a{
  // color: $black;
}
.nav>li>a:focus, .nav>li>a:hover {
  background-color: transparent;
  color: $black;
}


/* Menu */
.offcanvas {
  position: absolute;
  opacity: 0;
  // top: 128px;
  bottom: 0;
  z-index: 1001;
  // width: 300px;
  // width: 100%;
  width: 100%;
  height: 100%;
  // background: rgba(255,255,255, 1);
  // padding: 0 35px;
  // font-size: 1.15em;
  // transform: translate3d(-320px,0,0);
  transform: translate3d(-200%,0,0);
  transition: transform 0.4s;
  transition-timing-function: cubic-bezier(.7,0,.3,1);
  // border-left: 20px solid blue;
  // border-right: 20px solid blue;
}
/* Shown menu */
.show-menu .offcanvas {
  transform: translate3d(0,0,0);
  transition: transform .8s;
  transition-timing-function: cubic-bezier(.7,0,.3,1);
  opacity: 1;
}

.show-menu .offcanvas {
  transform: translateZ(0);
  transition: transform .8s;
  transition-timing-function: cubic-bezier(.7,0,.3,1);
}


@media (min-width: 992px){
  .offcanvas{
    position: inherit!important;
    transform: translate3d(0,0,0);
    // background: none;
    opacity: 1;

  }
  .sidebar-container{
    background: none;
    padding-top: 0;
  }
}

