// @media (min-width: 768px){
//   #pl-2{
//     margin-left: 15px;
//   }
// }

h1.search-title,
.page-header h1{
  // border-left: 1px solid #a9a9a9;
  margin-bottom: 0;
  padding-bottom: 10px;
}

//
// STUDIOS
//
figcaption.peopleName{
  justify-content: center;
  font-weight: bold;
  margin-top: 5px;
}
.panorama {
  height: 400px;
  overflow-x: scroll;
  overflow-y: hidden;
  img {
    height: 100%;
  }
}

.paver--ready div.paver__scroller{
  background-color: transparent;
  border-bottom: 1px solid black;
  opacity: 1;
  height: 3px;
}
.paver--ready div.paver__scroller span{
  background-color: black;
  border-radius: 0;
  height: 3px;
  width: 8%;
}


//
// CONTACT
//
div.wpcf7{
  p{
    margin-bottom: 0;
  }
  label{
    text-align: center;
    font-weight: bold;

  }
  textarea,
  input{
    padding: 0px;
    margin: 0px;
    // height: 18px;
    text-align: center;
    width: 100%;
    border: 0px;
    background: #ffffff;
    border-bottom: 1px dotted #999999;
  }
  textarea{
    // height: auto;
  }

  .send_btn {
    background: transparent;
    color: #004a74;
    font-size: 18px;
    border: 0px;
    font-weight: normal;
    height: 24px !important;
  }
  .send_btn:hover{
    cursor: pointer;
  }

}
.rechtliches{
  h1,h2,h3,h4{
    text-align: center;
  }
  h1,h2{
    font-size: 30px;
  }
}

//
// NEWSLETTER
//

#mc4wp-form-1{
  label{
    font-weight: 700;
    width: 100%;
  }
  input{
    padding: 0;
    margin: 0;
    text-align: center;
    width: 100%;
    border: 0;
    background: #fff;
    border-bottom: 1px dotted #999;
  }
  input[type="submit"]{
    background: transparent;
    color: #004a74;
    font-size: 18px;
    border: 0;
    font-weight: 400;
    height: 24px!important;
  }
  input[type="submit"]:hover{
    cursor: pointer;
  }
}



//
// PRESS
//
.pressDownloadsProjects,
.pressDownloadsStudio{
  hr{
    width: 30px;
    border-top: 2px solid #000;
    margin-top: .5rem;
    margin-bottom: .8rem;
  }
}
