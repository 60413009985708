// Grid system
// .main {
//   @include make-col-ready();
//   @include media-breakpoint-up(sm) {
//     @include make-col($main-sm-columns);
//     .sidebar-primary & {
//       @include make-col($main-sm-columns - $sidebar-sm-columns);
//     }
//   }
// }
// .sidebar {
//   @include make-col-ready();
//   @include media-breakpoint-up(sm) {
//     @include make-col($sidebar-sm-columns);
//   }
// }



// .grid{
//   display: flex;
// }


// @media (min-width: 768px){
@media (min-width: 992px){
  .sidebar {
    float: left;
    width: 25%;
  }
}

// @media (min-width: 768px){
@media (min-width: 992px){
  .main {
    float: left;
    width: 75%;
    max-width: 75%;

  }
}

// @media (min-width: 768px){
//   .container {
//     width: 100%;
//   }
// }
// @media (min-width: 992px){
//   .container {
//     width: 970px;
//   }
// }



