.footer{
  margin-top: 35px;
  margin-bottom: 80px;

  .borders{
    &.top{
      border-top: solid 2px $black;
    }
    &.bottom{
      border-bottom: 1px dotted $black;
    }
  }
}
