// @font-face {
//     font-family: 'GaramondClassicFSBoldItalic';
//     src: url('../fonts/GaramondClassic-BoldItalic-webfont.eot');
//     src: url('../fonts/GaramondClassic-BoldItalic-webfont.eot?iefix') format('eot'),
//          url('../fonts/GaramondClassic-BoldItalic-webfont.woff') format('woff'),
//          url('../fonts/GaramondClassic-BoldItalic-webfont.ttf') format('truetype'),
//          url('../fonts/GaramondClassic-BoldItalic-webfont.svg#webfontM0GVa5Ya') format('svg');
//     font-weight: normal;
//     font-style: normal;

// }
// @font-face {
//     font-family: 'GaramondClassicFSHeavy';
//     src: url('../fonts/GaramondClassic-Heavy-webfont.eot');
//     src: url('../fonts/GaramondClassic-Heavy-webfont.eot?iefix') format('eot'),
//          url('../fonts/GaramondClassic-Heavy-webfont.woff') format('woff'),
//          url('../fonts/GaramondClassic-Heavy-webfont.ttf') format('truetype'),
//          url('../fonts/GaramondClassic-Heavy-webfont.svg#webfont6OqgYzeS') format('svg');
//     font-weight: normal;
//     font-style: normal;

// }
@font-face {
    font-family: 'GaramondClassicFSItalic';
    src: url('../fonts/GaramondClassic-Italic-webfont.eot');
    src: url('../fonts/GaramondClassic-Italic-webfont.eot?iefix') format('eot'),
         url('../fonts/GaramondClassic-Italic-webfont.woff') format('woff'),
         url('../fonts/GaramondClassic-Italic-webfont.ttf') format('truetype'),
         url('../fonts/GaramondClassic-Italic-webfont.svg#webfontH3MSfC1r') format('svg');
    font-weight: normal;
    font-style: normal;

}
// @font-face {
//     font-family: 'GaramondClassicFSRegular';
//     src: url('../fonts/GaramondClassic-Regular-webfont.eot');
//     src: url('../fonts/GaramondClassic-Regular-webfont.eot?iefix') format('eot'),
//          url('../fonts/GaramondClassic-Regular-webfont.woff') format('woff'),
//          url('../fonts/GaramondClassic-Regular-webfont.ttf') format('truetype'),
//          url('../fonts/GaramondClassic-Regular-webfont.svg#webfont6YFUM9Mf') format('svg');
//     font-weight: normal;
//     font-style: normal;

// }
@font-face {
    font-family: 'GaramondClassicFSBold';
    src: url('../fonts/GaramondClassic-Bold-webfont.eot');
    src: url('../fonts/GaramondClassic-Bold-webfont.eot?iefix') format('eot'),
         url('../fonts/GaramondClassic-Bold-webfont.woff') format('woff'),
         url('../fonts/GaramondClassic-Bold-webfont.ttf') format('truetype'),
         url('../fonts/GaramondClassic-Bold-webfont.svg#webfontcMGoVEf7') format('svg');
    font-weight: normal;
    font-style: normal;

}
