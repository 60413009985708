

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
// $brand-primary:         #27ae60;
$brand-primary:         #000;
$black:                 #000;
$white:                 #FFF;
$grey:                  #959494;


$link-color:            #004a75;
$link-color-hover:      $brand-primary;
$link-color-active:     $brand-primary;

$background-color:      #f6f6f6;


$garamont:              GaramondClassicFSBold, "Times New Roman", Times, serif;
$times:                 "Times New Roman", Times, serif;






.bg-white{
  background: $white;
}
