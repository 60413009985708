body{
  color: $brand-primary;
  background: #f6f6f6 url(../images/aisslinger-background.jpg) no-repeat top left;
  font-size: 12px;
  font-family: $times;
  text-align: center;
  word-wrap: break-word;
}


::selection {
  background: $grey; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: $grey; /* Gecko Browsers */
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $link-color!important;
   opacity: 1;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $link-color!important;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $link-color!important;
}
:placeholder { /* Firefox 18- */
  color: $link-color!important
}





a:hover,
a:focus{
  text-decoration: none;
}


h1{
  font-size: 30px;
  font-family: $garamont;
}
h2{
  font-family: $garamont;
}
.wrap h3{
  // color: #000000;
  text-align: center;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: normal;
}
iframe{
  width: 100%;
  /* max-width: 100%; */
  height: auto;
}
img {
  width: 100%;
  height: auto;
}

.italic{
  font-style: italic;
}
.figcaption{
  font-size: 10px;
}


.vcenter{
  display: inline-block;
  vertical-align: middle;
  float: none;
}




.border-left{
  border-left: none;
}

// .border-left:nth-child(odd){
//   border-left: none;
// }


@media (min-width: 768px){
  .border-left{
    border-left: 1px solid #a9a9a9;
  }
}
@media (min-width: 992px){
  .border-left-md{
    border-left: 1px solid #a9a9a9;
  }
}


.alert{
  border-radius: 0;
}
.alert-warning{
  background-color: $grey;
  background-color: transparent;
  border-color: transparent;
  color: black;
}
