.grid{
  article{
    padding-bottom: 30px;
  }
  .data{
    margin-top: 5px;
    margin-bottom: 4px;
    line-height: 14px;
  }
  .categories{
    padding-bottom: 17px;
  }
}


article{
  text-align: center;
  header{
    ul{
      margin: 0;
      padding: 0;
      list-style: none;
      // display: flex;
      // justify-content: center;
    }
    li{
      display: inline-block;
    }
    li:after{
      content: ",";
      padding: 0 5px 0 0;
    }
    li:last-child:after{
      content: "";
      padding: 0;
    }

    h2{
      font-size: 26px;
      text-align: center;
      text-decoration: none;
      color: #000000;
      line-height: 28px;
      font-weight: normal;
    }
  }

  h1 {
    font-family: GaramondClassicFSBold, "Times New Roman", Times, serif;
    font-size: 30px;
    // text-align: center;
    // text-decoration: none;
    // color: #000000;
    font-weight: normal;
    padding: 0px;
    margin: 0px;
    padding-bottom: 12px;
  }
  .entry-summary{
    margin-top: 17px;
  }
  h2{
    margin: 0;
    font-family: $garamont;
    font-size: 18px;
    color: #000000;
    line-height: 20px;
    // max-width: 180px;
    font-weight: normal;
  }
}


figcaption,
.figure-caption, .wp-caption-text {
  display: flex;
  justify-content: space-between;
  // font-size: 10px;
  color: $black;
  line-height: 15px;
  padding: 0 2px;
}
header figcaption{
  font-size: 10px;
  font-style: italic;
}
.project-description{
  text-align: left;
  h2{
    padding-bottom: 15px;
    text-align: left;
  }
}
.project_details{
  padding-top: 15px;
  padding-bottom: 15px;
}
.project-text-right{
  border-left: 1px solid #a9a9a9;
}

.single-post-header{
  padding-bottom: 10px;
  h1{
    margin-top: 0;
  }
  .categories{
    padding-bottom: 20px;
  }
}

.more-information{
  margin-bottom: 30px;
}

.entry-content{
  p{
    margin-bottom: 0;
  }
}
@media (min-width: 768px){
  article .entry-content{
    border-right: 1px solid #a9a9a9;
  }
}

@media (min-width: 992px){
  article{
    border-left: 1px solid #a9a9a9;
  }
  .single-post-header{
    padding-bottom: 0;
  }
}

